import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  confirm(event) {
    event.stopPropagation();
    event.preventDefault();

    const EXPIRY_DAYS = 90;

    const acceptedAt = (new Date()).toUTCString();
    const d = new Date();
    d.setTime(d.getTime() + (EXPIRY_DAYS * 24 * 60 * 60 * 1000));
    const expireAt = d.toUTCString();

    document.cookie = `terms=${acceptedAt};expires=${expireAt};path=/;SameSite=None; Secure`;
    this.element.remove();
  }
}
