import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static targets = ['trigger', 'dropdown'];

  connect() {
    window.addEventListener('keydown', this.closeDropdown.bind(this));

    useClickOutside(this, { element: this.dropdownTarget });
  }

  disconnect() {
    window.removeEventListener('keydown', this.closeDropdown);
  }

  clickOutside(event) {
    if (this.triggerTarget.contains(event.target)) return;

    this.closeDropdown(event);
  }

  closeDropdown() {
    this.triggerTarget.setAttribute('aria-expanded', false);
    this.dropdownTarget.classList.add('hidden');
  }

  toggleDropdown() {
    const isOpened = this.triggerTarget.getAttribute('aria-expanded') === 'true';

    this.triggerTarget.setAttribute('aria-expanded', !isOpened);
    this.dropdownTarget.classList.toggle('hidden');
  }
}
