import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { useClickOutside, useDebounce } from 'stimulus-use';

export default class extends Controller {
  static targets = ['content', 'form', 'searchIcon', 'loaderIcon'];

  static debounces = [
    { name: 'debouncedSearch', wait: 300 },
  ];

  connect() {
    window.addEventListener('keydown', this.onEscape.bind(this));

    useClickOutside(this, { element: this.contentTarget });
    useDebounce(this);

    // Add event listener for submit event on the form
    this.formTarget.addEventListener('submit', this.onFormSubmit.bind(this));

    document.addEventListener('turbo:before-stream-render', this.showSearchIcon.bind(this));
  }

  disconnect() {
    window.removeEventListener('keydown', this.onEscape);

    // Remove event listener for submit event on the form
    this.formTarget.removeEventListener('submit', this.onFormSubmit);

    document.removeEventListener('turbo:before-stream-render', this.showSearchIcon.bind(this));
  }

  onEscape(event) {
    if (event.code !== 'Escape') return;

    event.preventDefault();
    this.remove();
  }

  clickOutside(event) {
    event.preventDefault();
    this.remove();
  }

  remove() {
    this.element.remove();
  }

  debouncedSearch() {
    this.searchIconTarget.dataset.visible = false;
    this.loaderIconTarget.dataset.visible = true;
    this.sendTurboRequest();
  }

  sendTurboRequest() {
    const url = `/search/search_suggestions?q=${this.formTarget.q.value}`;
    Turbo.visit(url, { action: 'replace' });
  }

  onFormSubmit(event) {
    // Prevent form submission if the search input is empty
    if (this.formTarget.q.value.trim() === '') {
      event.preventDefault();
    }
  }

  showSearchIcon() {
    this.searchIconTarget.dataset.visible = true;
    this.loaderIconTarget.dataset.visible = false;
  }
}
