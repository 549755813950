import { Controller } from 'stimulus';

export default class extends Controller {
  updateOverlay(event) {
    document.querySelector('#localeOverlay').textContent = event.params.language;
  }

  switchLocaleTab(event) {
    document.querySelectorAll('[data-locale-tab]').forEach((element) => {
      // eslint-disable-next-line no-param-reassign
      element.hidden = element.getAttribute('data-locale-tab') !== event.params.locale;
    });
  }
}
